<script>

import CustomTable from "@/components/tables/table.vue";
import CodePreviewModal from './components/PreviewModal.vue'
import CreateCodes from './components/CreateCodes.vue'
import AddPriceModal from './components/AddPriceModal.vue'
import CounterpartyApi from "../../../api/counterparty/CounterpartyApi";
import Swal from "sweetalert2";
import Statuses from "@/core/code/Statuses";
import LoadingTypes from "@/core/order/LoadingTypes";
import axios from "axios";

import Statistics from "@/views/pages/codes/components/Statistics.vue";
import CodeUpdateModal from "@/views/pages/codes/modals/CodeUpdate.vue";


export default {
  name: 'codes_list',
  data() {
    return {
      order_service_url: process.env.VUE_APP_ORDER_URL + '/',
      forwarders_has_been_loaded: false,
      table: {
        url: '/code/list/',
        excel_url: 'get_excel/',
        headers: [
          {
            label: 'ACT',
            field: 'act',
            align: 'center',
            excel_data: (code) => code.act || '',
            searchable: false
          },
          {
            label: 'APPLICATION NUMBER',
            field: 'application_number',
            align: 'center',
          },
          {
            label: 'FORWARDER',
            field: 'forwarder',
            align: 'center',
            searchType: 'select',
            searchOptions: [],
          },
          {
            label: 'ORDER NUMBER',
            field: 'order',
            align: 'center',
            excel_data: (code) => code.order ? code.order.order_number || '' : ''
          },
          {
            label: 'CODE',
            field: 'number',
            align: 'center',
          },
          {
            label: 'SUB CODE',
            field: 'sub_number',
            align: 'center',
            visible: false
          },
          {
            label: 'RATE',
            field: 'rate',
            align: 'center',
            visible: false
          },
          {
            label: 'CHARGES',
            field: 'charges',
            align: 'center',
            visible: false
          },
          {
            label: 'ADD CHARGES',
            field: 'add_charges',
            align: 'center',
            visible: false
          },
          {
            label: 'TOTAL COST',
            field: 'total_cost',
            align: 'center',
            visible: false
          },
          {
            label: 'DEPARTURE',
            field: 'departure',
            align: 'center',
            visible: false,
            excel_data: (code) => code.departure
                ? `${code.departure.name || ''} (${code.departure.code || ''})`
                : ''
          },
          {
            label: 'DESTINATION',
            field: 'destination',
            align: 'center',
            visible: false,
            excel_data: (code) => code.destination
                ? `${code.destination.name || ''} (${code.destination.code || ''})`
                : ''
          },
          {
            label: 'PRODUCT',
            field: 'product',
            align: 'center',
            visible: false,
            excel_data: (code) => code.product
                ? {
                  richText: [
                    {
                      text: code.product.name || '',
                    },
                    {text: '\n'},
                    {
                      text: `(${code.product.hc_code || ''})`,
                      align: 'center',
                    },
                    {text: '\n'},
                    {
                      text: `(${code.product.etcng_code || ''})`,
                      align: 'center',
                    },
                  ],
                  align: 'center',
                  wordWrap: true
                }
                : ''
          },
          {
            label: 'STATUS',
            field: 'status',
            align: 'center',
            searchType: 'select',
            searchOptions: Statuses,
          },
          {
            label: 'LOADING TYPE',
            field: 'loading_type',
            align: 'center',
            searchType: 'select',
            searchOptions: LoadingTypes,
            excel_data: (code) => code.loading_type
                ? code.loading_type
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                : ''
          },
          {
            label: 'CONTAINER/WAGON',
            field: 'container',
            align: 'center',
          },
          {
            label: 'CONTAINER TYPE',
            field: 'container_type',
            align: 'center',
            visible: false
          },
          {
            label: 'WEIGHT',
            field: 'weight',
            align: 'center',
            visible: false
          },
          {
            label: 'SMGS FILE',
            field: 'smgs_file',
            align: 'center',
            excel_data: (code) => code.smgs_file
                ? {
                  text: 'SMGS',
                  hyperlink: `${process.env.VUE_APP_ORDER_URL}/${code.smgs_file}` // Actual link URL
                }
                : '',
            searchable: false
          },
          {
            label: 'SMGS NUMBER',
            field: 'smgs_number',
            align: 'center',
          },
          {
            label: 'COMMENT',
            field: 'comment',
            align: 'center',
          },
          {
            label: 'DATE',
            field: 'date',
            align: 'center',
          },
          {
            label: 'MANAGER',
            field: 'user',
            align: 'center',
            excel_data: (code) => code.user ? code.user.username ? code.user.username : '' : ''
          },
          {
            label: 'actions',
            field: 'actions',
          },
        ],
        per_page: 10
      },
      currentCode: {},
      getUpdate: false,
      forwarders: [],
      create_code_order_type: '',
      codeWidgets: {}
    }
  },
  components: {
    CustomTable,
    CodePreviewModal,
    CreateCodes,
    AddPriceModal,
    Statistics,
    CodeUpdateModal
  },
  computed: {
    headerComputed: {
      get() {
        return this.table.headers.map(header => {
          if (header.field === 'forwarder') {
            let forwarders = this.forwarders
            forwarders.unshift({value: '', label: ''})
            return {
              ...header,
              searchOptions: forwarders
            };
          }
          return header;
        });
      }
    }
  },
  methods: {
    setToCurrentCode(data) {
      this.currentCode = data
    },
    async getForwarders() {
      let api = new CounterpartyApi()
      let response = await api.getCounterparties('?is_used_for_code=true')
      this.forwarders = response.results.map(forwarder => {
        return {
          value: forwarder.id,
          label: forwarder.name
        }
      })
      this.updateHeaders()
      this.forwarders_has_been_loaded = true
    },
    async confirmDelete(row) {
      await Swal.fire({
        position: "center",
        icon: "error",
        title: `You are about to delete code ${row.number}`,
        text: 'Deleting your code will remove all of its information from our database.',
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type Code${row.number} to confirm`,
        input: 'email',
        inputPlaceholder: `Code${row.number}`,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === 'Code' + row.number) {
              resolve(this.deleteCode(row.id))
            } else {
              resolve('Code number did not match :)')
            }
          })
        }
      });
    },
    async downloadFile(url) {
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    async deleteCode(id) {
      fetch(`${process.env.VUE_APP_ORDER_URL}/code/list/delete/${id}/`, {
        method: 'DELETE',
      }).then(response => {
        this.getUpdate = !this.getUpdate
        Swal.fire({
          position: "center",
          icon: response.ok ? "success" : "error",
          title: response.ok ? "Code Deleted" : "Code Delete Failed",
          text: response.ok ? "Code has been deleted successfully" : "Code could not be deleted",
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          cancelButtonColor: 'transparent',
          focusConfirm: false,
        });
      })
    },
    async getCodeWidgets() {
      let response = await axios.get("/code/statistics/")
      this.codeWidgets = response.data
    },
    updateHeaders() {
      this.table.headers = this.table.headers.map(header => {
        if (header.field === 'forwarder') {
          let forwarders = this.forwarders
          forwarders.unshift({value: '', label: ''})
          return {
            ...header,
            searchOptions: forwarders
          };
        }
        return header;
      });
    }
  },
  async mounted() {
    await this.getForwarders()
    await this.getCodeWidgets()
  }
}
</script>

<template>
  <Statistics/>

  <CodeUpdateModal ref="codeUpdateModal" @onCodeUpdated="this.$refs.codes_table.getData()"/>

  <div class="mb-4">
    <CustomTable :url="table.url" :excel_url="table.excel_url" name="CODES TABLE" id="codes_table"
                 :headers="table.headers" :searchable="true" :selectable="true" :perPage="table.per_page"
                 :getUpdate="getUpdate" ref="codes_table"
                 v-if="forwarders_has_been_loaded"
    >

      <template v-slot:top-right>
        <b-button data-bs-toggle="modal" data-bs-target="#AddPriceModal" variant="success" class="me-2">
          Add Code Price
        </b-button>
        <div class="btn-group ms-1">
          <button type="button" class="btn btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            Connect codes
          </button>
          <div class="dropdown-menu dropdownmenu-success">
            <span class="dropdown-item cursor-pointer" data-bs-toggle="modal" data-bs-target="#codeCreateModal"
                  @click="create_code_order_type = 'container'">To Container</span>
            <span class="dropdown-item cursor-pointer" data-bs-toggle="modal" data-bs-target="#codeCreateModal"
                  @click="create_code_order_type = 'wagon'">To Wagon</span>
            <span class="dropdown-item cursor-pointer" data-bs-toggle="modal" data-bs-target="#codeCreateModal"
                  @click="create_code_order_type = 'wagon(empty)'">To Empty wagon</span>
          </div>
        </div>

      </template>

      <template v-slot:application_number="slotProps">
        <h5 class="fw-medium link-primary my-0">
          <span class="badge badge-soft-primary">
            {{ slotProps.row.application_number }}
          </span>
        </h5>
      </template>

      <template v-slot:forwarder="slotProps">
        {{ slotProps.row.forwarder }}
        <!--      <span v-if="slotProps.row.forwarder === null" class="text-danger">&#45;&#45;</span>-->
        <!--      <span v-else>{{ forwarders.filter(i => i.value === slotProps.row.forwarder_id)[0]['label'] }}</span>-->
      </template>

      <template v-slot:order="slotProps">
        <span v-if="slotProps.row.order === null" class="text-danger">--</span>
        <div v-else class="link-primary fw-medium">
          <router-link v-if="slotProps.row.loading_type === 'container'"
                       :to="{ name: 'orders_container_detail', params: { id: slotProps.row.order.order_number } }">
            <span>{{ slotProps.row.order.order_number }}</span>
          </router-link>
          <router-link v-else-if="slotProps.row.loading_type === 'wagon'"
                       :to="{ name: 'orders_wagon_detail', params: { id: slotProps.row.order.order_number } }">
            <span>{{ slotProps.row.order.order_number }}</span>
          </router-link>
          <router-link v-else
                       :to="{ name: 'orders_empty_wagon_detail', params: { id: slotProps.row.order.order_number } }">
            <span>{{ slotProps.row.order.order_number }}</span>
          </router-link>
        </div>
      </template>

      <template v-slot:number="slotProps">
        <span class="badge badge-outline-primary cursor-pointer" data-bs-toggle="modal"
              data-bs-target="#CodePreviewModal"
              @click="setToCurrentCode(slotProps.row)">{{ slotProps.row.number }}
        </span>
      </template>

      <template v-slot:sub_number="{ row: code }">

        <VTooltip>

          <div v-if="code.sub_codes.map(c => c.number).length <= 3">
            <span v-for="sub_code in code.sub_codes" :key="`sub_code_` + sub_code.number"
                  class="badge badge-outline-success mx-1">
              {{ sub_code.number }}
            </span>
          </div>

          <div v-else-if="code.sub_codes.map(c => c.number).length > 3">
            <span v-for="sub_code in code.sub_codes.slice(0, 3)" :key="`sub_code_` + sub_code.number"
                  class="badge badge-outline-success mx-1">
              {{ sub_code.number }}
            </span> ...
            <span class="badge badge-outline-light border border-dark text-dark mx-1">
              + {{ code.sub_codes.map(c => c.number).length - 3 }}
            </span>
          </div>

          <template v-slot:popper>
            <table class="table table-nowrap">
              <tbody>
              <tr class="text-center" v-for="(ct, i) in code.sub_codes" :key="`table_subcode_` + ct.number">
                <th scope="row">
                  <span class="text-white">{{ i + 1 }}</span>
                </th>
                <td scope="row">
                  <span class="text-white">{{ ct.number }}</span>
                </td>
                <td scope="row">
                  <span class="text-white">{{ ct.territory ? ct.territory.name : '--' }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </VTooltip>
      </template>


      <template v-slot:total_cost="slotProps">
        <VTooltip>
          <span>{{ slotProps.row.total_cost }}</span>
          <template #popper>
            <div>
              <div class="d-flex justify-content-between border-bottom py-2">
                <span class="fw-medium">Rate</span>
                <span class="fw-medium">${{ slotProps.row.rate }}</span>
              </div>
              <div class="d-flex justify-content-between border-bottom py-2">
                <span class="fw-medium">Charges</span>
                <span class="fw-medium">${{ slotProps.row.charges }}</span>
              </div>
              <div class="d-flex justify-content-between border-bottom py-2">
                <span class="fw-medium me-3">Add Charges</span>
                <span class="fw-medium">${{ slotProps.row.add_charges }}</span>
              </div>
            </div>
          </template>
        </VTooltip>
      </template>


      <template v-slot:act="{row: code }">
        <div v-if="code.act && code.forwarder_slug">
          <router-link :to="{name: 'counterparty_profile_contracts_acts',
         params: {
          slug: code.forwarder_slug,
          contract_slug: code.act.contract_slug,
        }, query: {
            act: code.act.act_slug
        }
          }" class="link-primary cursor-pointer">
            {{ code.act.name || '-' }}
          </router-link>
        </div>
        <span v-else></span>
      </template>

      <template v-slot:loading_type="slotProps">
        <span v-if="slotProps.row.loading_type === null" class="text-danger">--</span>
        <span v-else>{{
            slotProps.row.loading_type.charAt(0).toUpperCase() + slotProps.row.loading_type.slice(1)
          }}</span>
      </template>

      <template v-slot:departure="slotProps">
        <span v-if="slotProps.row.departure === null" class="text-danger">--</span>
        <span v-else>{{ slotProps.row.departure.name + '(' + slotProps.row.departure.code + ')' }}</span>
      </template>

      <template v-slot:destination="slotProps">
        <span v-if="slotProps.row.destination === null" class="text-danger">--</span>
        <span v-else>{{ slotProps.row.destination.name + '(' + slotProps.row.destination.code + ')' }}</span>
      </template>

      <template v-slot:product="slotProps">
        <span v-if="slotProps.row.product === null" class="text-danger">--</span>
        <div v-else style="max-width: 180px">
          <VTooltip class="text-truncate">
            <span>{{ slotProps.row.product.name }}</span>
            <template #popper>
              <span>{{ slotProps.row.product.name }}</span>
            </template>
          </VTooltip>
        </div>
      </template>

      <template v-slot:status="slotProps">
        <span class="badge" :class="{
          'bg-warning': slotProps.row.status === 'checking',
          'bg-secondary': slotProps.row.status === 'used',
          'bg-success': slotProps.row.status === 'completed',
          'bg-danger': slotProps.row.status === 'canceled',
        }">{{ slotProps.row.status }}</span>
      </template>

      <template v-slot:container="slotProps">
        <span v-if="slotProps.row.container">{{ slotProps.row.container }}</span>
        <span v-else class="text-danger">-</span>
      </template>

      <template v-slot:smgs_file="slotProps">
        <span v-if="slotProps.row.smgs_file === null" class="text-danger">-</span>

        <span v-if="slotProps.row.smgs_file !== null"
              @click="downloadFile(this.order_service_url + slotProps.row.smgs_file)" class="text-danger">
          <font-awesome-icon icon="fa-solid fa-file-zipper" class="c_icon_hoverable text-secondary"/>
        </span>
      </template>
      <template v-slot:smgs_number="slotProps">
        <span v-if="slotProps.row.smgs_number === null" class="text-danger">-</span>

        <span v-if="slotProps.row.smgs_number !== null">
          {{ slotProps.row.smgs_number }}
        </span>
      </template>
      <template v-slot:user="{ row: code }">
        <template v-if="code.user">
          <router-link :to="{ name: 'user_profile', params: { slug: code.user.slug } }">
            <div class="d-flex align-items-center">
              <div class="avatar-xxs flex-shrink-0 me-1">
                <span class="avatar-title bg-light text-primary rounded-circle">
                  {{ code.user.username[0].toUpperCase() }}
                </span>
              </div>
              <span>{{ code.user.username }}</span>
            </div>
          </router-link>
        </template>
        <span v-else>--</span>
      </template>

      <template v-slot:actions="slotProps">

        <div class="w-100 text-center">
          <font-awesome-icon
              @click="this.$refs.codeUpdateModal.openModal(slotProps.row)"
              icon="fa-solid fa-pen-to-square"
              class="c_icon me-1 c_icon_hoverable text-dark"
          />

          <font-awesome-icon @click="confirmDelete(slotProps.row)" icon="fa-solid fa-trash"
                             class="c_icon c_icon_hoverable text-danger"/>
        </div>
      </template>

    </CustomTable>
  </div>

  <AddPriceModal :forwarders="forwarders.filter(i => i.label !== '')"/>
  <CodePreviewModal :codeData="currentCode"/>
  <CreateCodes :forwarders="forwarders.filter(i => i.label !== '')" :order_type="create_code_order_type"/>
</template>

<style></style>

<script>
import SelectStations from "@/components/custom/SelectStations.vue";
import SelectProduct from "@/components/custom/SelectProduct.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CodeUpdate",
  emits: ['onCodeUpdated'],
  data() {
    return {
      showModal: false,
      request: {
        status: 'initial',
        message: '',
        details: ''
      },
      code: {},
      remove_this_order: false,
      options: {
        status: [
          {value: 'checking', label: 'Checking'},
          {value: 'used', label: 'Used'},
          {value: 'completed', label: 'Completed'},
          {value: 'canceled', label: 'Canceled'},
        ],
        loading_type: [
          {value: 'container', label: 'Container'},
          {value: 'wagon', label: 'Wagon'},
          {value: 'wagon(empty)', label: 'Wagon(empty)'},
        ],
      },
      initial_status: ''
    }
  },
  components: {
    SelectStations,
    SelectProduct,
    Multiselect
  },
  computed: {
    current_departure() {
      return this.code.departure || null
    },
    current_destination() {
      return this.code.destination || null
    },
    current_product() {
      return this.code.product || null
    }
  },
  methods: {
    openModal(code) {
      if (!code) return console.log('Code is not defined')
      this.code = {...code}
      this.initial_status = code.status
      this.showModal = true
    },
    async saveCode(cancel_code = false) {
      if (Object.keys(this.code).length <= 0) return console.log('Code is not defined')
      this.request.status = 'loading'
      try {
        await axios.put(`/code/list/update/${this.code.id}/`, {
          departure_id: this.code.departure.id,
          destination_id: this.code.destination.id,
          product_id: this.code.product.id,
          loading_type: this.code.loading_type,
          status: cancel_code ? 'canceled' : this.code.status,
          comment: this.code.comment,
          remove_order: this.remove_this_order,
        })
        this.$emit('onCodeUpdated')
        await Swal.fire({
          icon: 'success',
          title: cancel_code ? 'Code canceled successfully'
              : 'Code updated successfully',
          showConfirmButton: false,
          timer: 3500
        })
      } catch (e) {
        this.request.status = 'error'
        this.request.message = e.response.data.message || 'Error while saving this code'
        this.request.details = Object.entries(e.response.data.extra.fields) ? Object.entries(e.response.data.extra.fields).map(([key, value]) => {
          return `<span class="fw-medium">${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: </span> ${value}`
        }).join('<br>') : ''
        return
      }
      this.request.status = 'success'
    }
  },
}
</script>

<template>
  <b-modal v-model="showModal" title="Edit Code Info" centered hide-footer>

    <!-- Danger Alert -->
    <b-alert v-if="request.status === 'error'" dismissible show variant="danger" class="alert-additional fade show"
             role="alert">
      <div class="alert-body">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        <div class="d-flex">
          <div class="flex-shrink-0 me-3">
            <i class="ri-error-warning-line fs-16 align-middle"></i>
          </div>
          <div class="flex-grow-1">
            <h5 class="alert-heading">
              {{ request.message || 'Couldn\'t save this code' }}
            </h5>
            <p v-html="request.details || 'Reload the page or try again later'" class="mb-0"></p>
          </div>
        </div>
      </div>
    </b-alert>


    <!-- Danger Alert -->
    <b-alert v-if="initial_status === 'canceled'" show variant="danger"
             class="alert-label-icon rounded-label fade show" role="alert">
      <i class="ri-error-warning-line label-icon"></i><strong>ATTENTION</strong> - This code is canceled.
    </b-alert>


    <b-list-group class="mb-4">
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span class="fw-medium">Code number</span>
        <h5 class="mb-0">
          <span class="badge bg-success">{{ code.number }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span class="fw-medium">Application number</span>
        <h5 class="mb-0">
          <span class="badge badge-soft-primary">{{ code.application_number }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span class="fw-medium">Order number</span>
        <h5 class="mb-0">
          <span class="badge badge-soft-success">
            {{ code.order ? code.order.order_number : '--' }}
          </span>
        </h5>
      </b-list-group-item>
    </b-list-group>

    <form @submit.prevent="saveCode()">
      <b-row class="pb-2 border-bottom">
        <SelectStations :ratio="[6,6,6,6]"
                        :disabled_departure="code.order !== null"
                        :disabled_destination="code.order !== null"
                        :current_departure="current_departure"
                        :current_destination="current_destination"
        />
      </b-row>
      <b-row class="pt-3 pb-2 border-bottom">
        <SelectProduct :ratio="[12, 6, 6]"
                       :disabled="code.order !== null"
                       :current_product="current_product"
        />
      </b-row>
      <b-row class="pt-3" gutter-y="3">
        <b-col :lg="6">
          <label class="form-label">Loading type</label>
          <Multiselect
              :disabled="code.order !== null"
              v-model="code.loading_type" :options="options.loading_type"/>
        </b-col>
        <b-col :lg="6">
          <label class="form-label">Status</label>
          <Multiselect
              v-model="code.status" :options="options.status"/>
        </b-col>
        <b-col :sm="12" v-if="code.order !== null">
          <div class="form-check form-check-danger">
            <input v-model="remove_this_order" class="form-check-input" type="checkbox" id="removeCodeBox">
            <label class="form-check-label" for="removeCodeBox">
              Remove the order
            </label>
          </div>
          <p class="mb-0">
          <span class="text-muted">
            <i class="mdi mdi-alert-circle-outline"></i>
            If you check this box, the code will be removed from the order.
          </span>
          </p>
        </b-col>
        <b-col :sm="12">
        <textarea class="form-control" rows="5" v-model="code.comment"
                  placeholder="Leave your comment here..."></textarea>
        </b-col>
        <b-col :sm="12">
          <b-button
              :type="request.status === 'loading' ? 'button' : 'submit'" class="w-100 btn-border"
              variant="success" :disabled="request.status === 'loading'">
            {{ request.status === 'loading' ? 'Saving...' : 'Confirm & Save' }}
          </b-button>
        </b-col>
        <template v-if="initial_status !== 'canceled'">
          <b-col :sm="12" class="text-center text-muted">or</b-col>
          <b-col :sm="12">
            <b-button type="button" class="w-100" @click="saveCode(true)"
                      variant="outline-danger" :disabled="request.status === 'loading'">
              CANCEL THIS CODE
            </b-button>
          </b-col>
        </template>
      </b-row>
    </form>
  </b-modal>
</template>

<style scoped>

</style>
<script>
import {defineComponent} from 'vue'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";

export default defineComponent({
  name: "AddPriceModal",
  props: {
    forwarders: {
      type: Array,
      default: () => [],
    },
    order_type: String
  },
  data() {
    return {
      counterparty_id: null,
      codes: '',
      rates: '',
      charges: '',
      add_charges: '',

      is_validating: false,
      validation_error: false,
      validated_data: [],

      saving_codes_after_validation: false
    }
  },
  computed: {
    counterpartyOptions() {
      return this.forwarders.map((forwarder) => {
        return {
          value: forwarder.value,
          label: forwarder.label,
        };
      });
    },
    isFormValid() {
      return this.counterparty_id !== null && this.codes !== '' && this.rates !== '' && this.charges !== '' && this.add_charges !== ''
    }
  },
  methods: {
    async validateCodes() {
      this.is_validating = true
      try {
        let response = await axios.post(`/code/validate_code_exist/${this.counterparty_id}/`, {
          codes: this.codes.split('\n'),
          rates: this.rates.split('\n'),
          charges: this.charges.split('\n'),
          add_charges: this.add_charges.split('\n'),
        })
        this.validated_data = response.data
        setTimeout(() => {
          this.is_validating = false
        }, 1000)
      } catch {
        this.validation_error = true
      }
      return this.validated_data
    },
    async addPrices() {
      let validated_data = await this.validateCodes()
      if (validated_data.filter(code => !code.status).length > 0) return
      this.saving_codes_after_validation = true
      try {
        let response = await axios.put(`/code/price_update/${this.counterparty_id}/`, {
          codes: this.codes.split('\n'),
          rates: this.rates.split('\n'),
          charges: this.charges.split('\n'),
          add_charges: this.add_charges.split('\n'),
        })
        this.saving_codes_after_validation = false
        document.querySelector('#AddPriceModal .btn-close').click()
        this.counterparty_id = null
        this.codes = ''
        this.rates = ''
        this.charges = ''
        this.add_charges = ''
        this.validated_data = []
        this.is_validating = false
        this.saving_codes_after_validation = false
        await Swal.fire({
          icon: response.status ? 'success' : 'error',
          title: response.status ? 'Prices Added Successfully' : 'Something went wrong',
        })
      } catch {
        this.saving_codes_after_validation = false
        await Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }

    }
  },
  components: {
    Multiselect
  },
})
</script>

<template>
  <div id="AddPriceModal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content border-0 overflow-hidden">

        <div class="modal-header p-3">
          <h5 class="modal-title">Add Prices</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body pt-1" v-if="validated_data.length === 0">
          <form v-if="!is_validating" class="row" @submit.prevent="addPrices()">

            <div class="col-12 mb-3">
              <label class="form-label">Counterparty</label>
              <Multiselect :options="counterpartyOptions"
                           v-model="counterparty_id"
                           :searchable="true"
                           placeholder="Select counterparty"/>
            </div>

            <div class="col-12 col-lg-3 mb-3">
              <label class="form-label">Codes</label>
              <textarea v-model="codes"
                        class="form-control"
                        :placeholder="counterparty_id === null ? 'Select counterparty first' : 'Enter codes'"
                        :rows="10" :disabled="counterparty_id === null"/>
            </div>

            <div class="col-12 col-lg-3 mb-3">
              <label class="form-label">Rates</label>
              <textarea v-model="rates" class="form-control"
                        :placeholder="counterparty_id === null ? 'Select counterparty first' : 'Enter prices'"
                        :rows="10" :disabled="counterparty_id === null"/>
            </div>

            <div class="col-12 col-lg-3 mb-3">
              <label class="form-label">Charges</label>
              <textarea v-model="charges"
                        class="form-control"
                        :placeholder="counterparty_id === null ? 'Select counterparty first' : 'Enter codes'"
                        :rows="10" :disabled="counterparty_id === null"/>
            </div>

            <div class="col-12 col-lg-3 mb-3">
              <label class="form-label">Add Charges</label>
              <textarea v-model="add_charges"
                        class="form-control"
                        :placeholder="counterparty_id === null ? 'Select counterparty first' : 'Enter codes'"
                        :rows="10" :disabled="counterparty_id === null"/>
            </div>

            <div class="col-12 text-end">
              <b-button :disabled="!isFormValid" type="submit" variant="success">Save</b-button>
            </div>

          </form>
          <template v-else-if="is_validating">
            <div class="text-center py-5">
              <b-spinner></b-spinner>
              <h6 class="mb-0 mt-4">Validating Codes...</h6>
            </div>
          </template>
        </div>

        <div class="modal-body pt-1" v-else>
          <template v-if="validated_data.filter(code => !code.status).length > 0">
            <div class="p-3 rounded-3 bg-soft-success mb-3">
              <h5>Existing Codes</h5>
              <div class="d-flex gap-2">
                <template v-if="validated_data.filter(code => code.status).length > 0">
                  <span class="badge bg-success" v-for="code in validated_data.filter(code => code.status)"
                        :key="code.id">
                    {{ code.number }}
                  </span>
                </template>
                <template v-else>--</template>
              </div>
            </div>
            <div class="p-3 rounded-3 bg-soft-danger mb-3">
              <h5>Codes Not Found</h5>
              <div class="d-flex gap-2">
                <template v-if="validated_data.filter(code => !code.status).length > 0">
                  <span class="badge bg-danger" v-for="code in validated_data.filter(code => !code.status)"
                        :key="code.id">
                    {{ code.number }}
                  </span>
                </template>
                <template v-else>--</template>
              </div>
            </div>
            <div class="py-3 d-flex justify-content-between align-items-center">
              <h5>Please, correct the codes above and try again later !</h5>
              <b-button variant="success" @click="validated_data = []">
                <i class="bx bx-arrow-back me-1"></i>
                Try Again
              </b-button>
            </div>
          </template>
          <template v-else>
            <div class="text-center py-5" v-if="saving_codes_after_validation">
              <b-spinner></b-spinner>
              <h6 class="mb-0 mt-4">Saving Codes...</h6>
            </div>
            <div class="text-center py-5" v-else-if="!saving_codes_after_validation">
              <h6 class="my-5">Codes Updated Successfully</h6>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>
const LoadingTypes = [
    {
        value: '',
        label: '',
    },
    {
        value: 'container',
        label: 'Container',
    },
    {
        value: 'wagon',
        label: 'Wagon',
    },
    {
        value: 'wagon_empty',
        label: 'Empty Wagon',
    },
]

export default LoadingTypes
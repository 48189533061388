<script>
import SwiperCore, {Thumbs, Pagination, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Pagination, Navigation]);
import skeleton from "@/components/custom/skeleton.vue";
import axios from "axios";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  data() {
    return {
      statistics: [],
      is_loading: true,
      request_ok: true,
      months_dic: {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      }
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    skeleton
  },
  methods: {
    async getCodeStatistics() {
      try {
        this.is_loading = true
        let response = await axios.get('/code/statistic_by_forwarder/')
        this.statistics = response.data.statistics.map(item => {
          const lineColumnAreaChart = {
            series: [
              {
                name: 'Total Count',
                type: 'line',
                data: item.monthly_codes.map(code => code.total_count || 0)
              },
              {
                name: 'Total Price',
                type: 'area',
                data: item.monthly_codes.map(code => code.total_price || 0)
              },
            ],
            chartOptions: {
              chart: {
                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                  show: false,
                },
              },
              stroke: {
                width: [3, 3],
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false,
              },
              fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                  inverseColors: false,
                  shade: 'light',
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100]
                }
              },
              tooltip: {
                shared: true,
                intersect: false,
                y: {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(0)
                    }
                    return y;
                  }
                }
              },
              legend: {
                show: false
              },
              labels: item.monthly_codes.map(code => this.months_dic[code.month]),
              xaxis: {
                categories: item.monthly_codes.map(code => this.months_dic[code.month]),
              },
              yaxis: [
                {
                  labels: {
                    show: false
                  }
                },
                {
                  labels: {
                    show: false
                  }
                }
              ],
              colors: getChartColorsArray('["--vz-primary", "--vz-success", "--vz-danger"]'),
            }
          };
          return {
            ...item,
            chartOptions: lineColumnAreaChart.chartOptions,
            series: lineColumnAreaChart.series,
          }
        })
        this.is_loading = false
        this.request_ok = true
      } catch {
        this.statistics = []
        this.is_loading = false
        this.request_ok = false
      }
    },
    isCurrentlySelected(forwarder_id, status) {
      if (forwarder_id && status) {
        return this.$route.query.forwarder === forwarder_id.toString() && this.$route.query.status === status
      }
      return false
    },
    async clearFilters() {
      await this.$router.push({
        name: 'codes_list',
        query: {}
      });
    }
  },
  mounted() {
    this.getCodeStatistics()
  }
};

</script>

<template>
  <b-row>
    <b-col lg="12">
      <template v-if="!is_loading">
        <swiper v-if="request_ok" class="cryptoSlider" :slidesPerView="1"
                :loop="false" :spaceBetween="24"
                :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
                :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
              }"
                :breakpoints="{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2.5,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
              }"
        >
          <template v-for="(item, index) of statistics" :key="index">
            <swiper-slide>
              <b-card no-body>
                <b-card-body class="px-0 pb-0">
                  <div class="d-flex align-items-center px-3">
                    <h6 class="mb-0 fs-14 fw-medium">{{ item.application__forwarder__name }}</h6>
                  </div>
                  <b-row class="align-items-end g-0 mb-1">
                    <b-col cols="12">
                      <div class="px-3">
                        <div>
                          <h5 class="mb-1 mt-4">
                            $ {{ (item.total_price || 0).toLocaleString() }}
                          </h5>
                          <p class="text-success fs-13 fw-medium mb-0">
                            {{ item.total_codes }}
                            <span class="text-muted ms-2 fs-10 text-uppercase">(total codes)</span>
                          </p>
                        </div>
                        <div class="table-responsive mt-3">
                          <table class="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                            <tbody class="border-0">
                            <tr :class="{
                                    'bg-soft-warning': isCurrentlySelected(
                                        item.application__forwarder__id, 'checking'),
                                }">
                              <td>
                                <h4 class="text-truncate fs-14 fs-medium mb-0">
                                  <i class="ri-stop-fill align-middle fs-18 text-warning me-2"></i>
                                  Checking Codes
                                </h4>
                              </td>
                              <td>
                                <span v-if="item.application__forwarder__id">
                                  <router-link v-if="!isCurrentlySelected(item.application__forwarder__id, 'checking')" :to="{name: 'codes_list', query: {
                                  forwarder: item.application__forwarder__id,
                                  status: 'checking'
                                }}" class="text-muted mb-0"
                                  >
                                    {{ item.checking_status || 0}}
                                    <i :class="{
                                      'mdi mdi-eye-outline': this.$route.name === 'codes_list',
                                      'ri-external-link-line': this.$route.name !== 'codes_list',
                                    }"
                                       class="link-secondary ms-1">
                                    </i>
                                </router-link>
                                  <span v-else @click="clearFilters()" class="text-muted cursor-pointer">
                                   {{ item.checking_status || 0 }}
                                   <i class="mdi mdi-eye-off-outline ms-1 text-danger"></i>
                                 </span>
                                </span>
                                <span v-else class="text-muted mb-0">{{ item.checking_status || 0 }}</span>
                              </td>
                              <td class="text-end">
                                <p class="text-success fw-medium fs-12 mb-0">
                                  <i class="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                  $ {{ (item.checking_total_price || 0).toLocaleString() }}
                                </p>
                              </td>
                            </tr>
                            <tr :class="{
                                    'bg-soft-secondary': isCurrentlySelected(
                                        item.application__forwarder__id, 'used'),
                                }">
                              <td>
                                <h4 class="text-truncate fs-14 fs-medium mb-0">
                                  <i class="ri-stop-fill align-middle fs-18 text-secondary me-2"></i>
                                  Used Codes
                                </h4>
                              </td>
                              <td>
                                <span v-if="item.application__forwarder__id">
                                  <router-link v-if="!isCurrentlySelected(item.application__forwarder__id, 'used')" :to="{name: 'codes_list', query: {
                                  forwarder: item.application__forwarder__id,
                                  status: 'used'
                                }}" class="text-muted mb-0"
                                  >
                                    {{ item.used_status || 0 }}
                                    <i :class="{
                                      'mdi mdi-eye-outline': this.$route.name === 'codes_list',
                                      'ri-external-link-line': this.$route.name !== 'codes_list',
                                    }"
                                       class="link-secondary ms-1">
                                    </i>
                                </router-link>
                                   <span v-else @click="clearFilters()" class="text-muted cursor-pointer">
                                   {{ item.used_status || 0}}
                                   <i class="mdi mdi-eye-off-outline ms-1 text-danger"></i>
                                 </span>
                                </span>
                                <span v-else class="text-muted mb-0">{{ item.used_status || 0 }}</span>
                              </td>
                              <td class="text-end">
                                <p class="text-success fw-medium fs-12 mb-0">
                                  <i class="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                  $ {{ (item.used_total_price || 0).toLocaleString() }}
                                </p>
                              </td>
                            </tr>
                            <tr :class="{
                                    'bg-soft-success': isCurrentlySelected(
                                        item.application__forwarder__id, 'completed'),
                                }">
                              <td>
                                <h4 class="text-truncate fs-14 fs-medium mb-0">
                                  <i class="ri-stop-fill align-middle fs-18 text-success me-2"></i>
                                  Completed Codes
                                </h4>
                              </td>
                              <td>
                                <span v-if="item.application__forwarder__id">
                                  <router-link v-if="!isCurrentlySelected(item.application__forwarder__id, 'completed')"
                                               :to="{name: 'codes_list', query: {
                                  forwarder: item.application__forwarder__id,
                                  status: 'completed'
                                }}" class="text-muted mb-0"
                                  >
                                    {{ item.completed_status || 0 }}
                                    <i :class="{
                                      'mdi mdi-eye-outline': this.$route.name === 'codes_list',
                                      'ri-external-link-line': this.$route.name !== 'codes_list',
                                    }"
                                       class="link-secondary ms-1">
                                    </i>
                                </router-link>
                                  <span v-else @click="clearFilters()" class="text-muted cursor-pointer">
                                   {{ item.completed_status || 0 }}
                                   <i class="mdi mdi-eye-off-outline ms-1 text-danger"></i>
                                 </span>
                                </span>
                                <span v-else class="text-muted mb-0">{{ item.completed_status || 0 }}</span>
                              </td>
                              <td class="text-end">
                                <p class="text-success fw-medium fs-12 mb-0">
                                  <i class="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                  $ {{ (item.completed_total_price || 0).toLocaleString() }}
                                </p>
                              </td>
                            </tr>
                            <tr :class="{
                                    'bg-soft-danger': isCurrentlySelected(item.application__forwarder__id, 'canceled'),
                                }"
                            >
                              <td>
                                <h4 class="text-truncate fs-14 fs-medium mb-0">
                                  <i class="ri-stop-fill align-middle fs-18 text-danger me-2"></i>
                                  Canceled Codes
                                </h4>
                              </td>
                              <td>
                               <span v-if="item.application__forwarder__id">
                                  <router-link v-if="!isCurrentlySelected(item.application__forwarder__id, 'canceled')"
                                               :to="{name: 'codes_list', query: {
                                  forwarder: item.application__forwarder__id,
                                  status: 'canceled'
                                }}" class="text-muted mb-0"
                                  >
                                    {{ item.canceled_status || 0 }}
                                    <i :class="{
                                      'mdi mdi-eye-outline': this.$route.name === 'codes_list',
                                      'ri-external-link-line': this.$route.name !== 'codes_list',
                                    }"
                                       class="link-secondary ms-1">
                                    </i>
                                </router-link>
                                 <span v-else @click="clearFilters()" class="text-muted cursor-pointer">
                                   {{ item.canceled_status || 0 }}
                                   <i class="mdi mdi-eye-off-outline ms-1 text-danger"></i>
                                 </span>
                                </span>
                                <span v-else class="text-muted mb-0">{{ item.canceled_status || 0 }}</span>
                              </td>
                              <td class="text-end">
                                <p class="text-success fw-medium fs-12 mb-0">
                                  <i class="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                  $ {{ (item.canceled_total_price || 0).toLocaleString() }}
                                </p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="ps-0">
                      <apexchart class="apex-charts" :options="item.chartOptions" height="180" dir="ltr"
                                 :series="item.series.length > 0 ? item.series : []" :key="item.id"></apexchart>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </swiper-slide>
          </template>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-pagination"></div>
        </swiper>
        <!-- Danger Alert -->
        <b-alert v-else show variant="danger" class="alert-additional fade show" role="alert">
          <div class="alert-body">
            <div class="d-flex">
              <div class="flex-shrink-0 me-3">
                <i class="ri-error-warning-line fs-16 align-middle"></i>
              </div>
              <div class="flex-grow-1">
                <h5 class="alert-heading">Couldn't load codes statistics</h5>
                <p class="mb-0">
                  <b>Please, reload the page or try again late.</b>
                  If problem persists, please contact IT department
                </p>
              </div>
            </div>
          </div>
        </b-alert>
      </template>
      <swiper v-else-if="is_loading" class="cryptoSlider" :slidesPerView="1"
              :loop="false" :spaceBetween="24"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
              }"
              :breakpoints="{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2.5,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
              }"
      >
        <template v-for="(item, index) of 5" :key="index">
          <swiper-slide>
            <b-card no-body>
              <b-card-body class="px-0">
                <div class="d-flex align-items-center px-3">
                  <h6 class="mb-0 fs-14 fw-medium" style="min-width: 150px">
                    <skeleton class_list="py-2"/>
                  </h6>
                </div>
                <b-row class="align-items-end g-0">
                  <b-col cols="12">
                    <div class="px-3">
                      <div>
                        <h5 class="mb-1 mt-4 text-muted">
                          $ 0.00
                        </h5>
                        <p class="text-muted fs-13 fw-medium mb-0">
                          0.00
                          <span class="text-muted ms-2 fs-10 text-uppercase">(total codes)</span>
                        </p>
                      </div>
                      <div class="table-responsive mt-3">
                        <table class="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                          <tbody class="border-0">
                          <tr>
                            <td>
                              <h4 class="text-truncate fs-14 fs-medium mb-0">
                                <i class="ri-stop-fill align-middle fs-18 text-warning me-2"></i>
                                Checking Codes
                              </h4>
                            </td>
                            <td>
                              <p class="text-muted mb-0">
                                <skeleton style="min-width: 75px"/>
                              </p>
                            </td>
                            <td class="text-end">
                              <p class="text-success fw-medium fs-12 mb-0">
                                <i class="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                $ 0.00
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4 class="text-truncate fs-14 fs-medium mb-0">
                                <i class="ri-stop-fill align-middle fs-18 text-secondary me-2"></i>
                                Used Codes
                              </h4>
                            </td>
                            <td>
                              <p class="text-muted mb-0">
                                <skeleton/>
                              </p>
                            </td>
                            <td class="text-end">
                              <p class="text-success fw-medium fs-12 mb-0">
                                <i class="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                $ 0.00
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4 class="text-truncate fs-14 fs-medium mb-0">
                                <i class="ri-stop-fill align-middle fs-18 text-success me-2"></i>
                                Completed Codes
                              </h4>
                            </td>
                            <td>
                              <p class="text-muted mb-0">
                                <skeleton/>
                              </p>
                            </td>
                            <td class="text-end">
                              <p class="text-success fw-medium fs-12 mb-0">
                                <i class="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                $ 0.00
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4 class="text-truncate fs-14 fs-medium mb-0">
                                <i class="ri-stop-fill align-middle fs-18 text-danger me-2"></i>
                                Cancelled Codes
                              </h4>
                            </td>
                            <td>
                              <p class="text-muted mb-0">
                                <skeleton/>
                              </p>
                            </td>
                            <td class="text-end">
                              <p class="text-success fw-medium fs-12 mb-0">
                                <i class="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                $ 0.00
                              </p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </swiper-slide>
        </template>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div>
      </swiper>
    </b-col>
  </b-row>
</template>
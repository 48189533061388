const Statuses = [
    {
        value: '',
        label: '',
    },
    {
        value: 'checking',
        label: 'Checking',
    },
    {
        value: 'used',
        label: 'Used',
    },
    {
        value: 'canceled',
        label: 'Canceled',
    },
    {
        value: 'completed',
        label: 'Completed',
    }
];
export default Statuses